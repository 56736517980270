import React, {MutableRefObject, useEffect, useRef, useState} from "react";
import {StaticImage} from "gatsby-plugin-image";
import Button from "../../../Button/Button";
import {Link} from "gatsby";
import classNames from "classnames";

const styles = require("./Hero.module.scss");

interface Props {
    animationVideoRef: MutableRefObject<HTMLVideoElement | null>;
}

function calculatePrimaryBtn(width: number | null) {
    if (width === null) {
        return {
            text: "Start your free trial",
            url: 'https://app.prestavi.com/signup'
        }
    }

    // if (window.innerWidth < 768) {
    //     return {
    //         text: "Schedule a demo",
    //         url: "/demo"
    //     }
    // }

    return {
        text: "Start your free trial",
        url: 'https://app.prestavi.com/signup'
    }
}

const words = [
    'Customers',
    'Employees',
    'Vendors',
    'Contractors',
    'Suppliers',
]

const Hero: React.FunctionComponent<Props> = ({animationVideoRef}) => {

    const [width, setWidth] = useState<number | null>(null);
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);


    }, []);

    function changePlayState(event) {
        event.preventDefault();

        if (!animationVideoRef.current) {
            return;
        }

        if (animationVideoRef.current.paused === true) {
            setIsPlaying(true);
            animationVideoRef.current.play();
            animationVideoRef.current.setAttribute("controls","controls")

            return;
        }

        setIsPlaying(false);
        animationVideoRef.current.removeAttribute("controls")
        animationVideoRef.current.pause();
    }

    // const primaryBtnProperties = calculatePrimaryBtn(width);

    let mobileScreenSize = false;

    if (width !== null && width < 768) {
        mobileScreenSize = true;
    }

    return (
        <div className={styles.Hero}>
            <div className={styles.Headline}>
                <h1>
                    <h1>The easier way to collaborate together and <span>move work forward.</span></h1>
                </h1>
                <p>
                    Prestavi offers a simple yet powerful way to bring people, process and automation together to help your team do more focused work.
                </p>
            </div>

            {/*<div className={styles.Actions}>*/}
            {/*    <div>*/}
            {/*        <Button className={styles.ActionButton}*/}
            {/*                onClick={() => window.location.href = primaryBtnProperties.url} theme="solid" tone="dark">*/}
            {/*            {primaryBtnProperties.text}*/}

            {/*            <svg width="11.028" height="18.393" viewBox="0 0 11.028 18.393">*/}
            {/*                <g transform="translate(0 0)">*/}
            {/*                    <g>*/}
            {/*                        <path*/}
            {/*                            d="M1.27,18.393a1.27,1.27,0,0,1-.891-2.174L7.9,8.811.43,2.222A1.27,1.27,0,1,1,2.11.318L10.6,7.808a1.269,1.269,0,0,1,.051,1.856L2.161,18.028a1.267,1.267,0,0,1-.891.365"*/}
            {/*                            fill="#265dde"/>*/}
            {/*                    </g>*/}
            {/*                </g>*/}
            {/*            </svg>*/}
            {/*        </Button>*/}

            {/*        /!*{!mobileScreenSize && (*!/*/}
            {/*        /!*	<Link className={styles.Schedule} to="/demo">Schedule a Demo</Link>*!/*/}
            {/*        /!*)}*!/*/}
            {/*    </div>*/}
            {/*    {!mobileScreenSize && (*/}
            {/*        <span>30-day free trial. No credit card required.</span>*/}
            {/*    )}*/}
            {/*</div>*/}

            <div className={styles.SilentAnimation}>
                {!isPlaying && (
                    <div className={styles.PlayBtn} onClick={changePlayState}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="113.934" height="113.93" viewBox="0 0 113.934 113.93">
                            <g transform="translate(-593 -748.15)">
                                <path d="M113.934,56.965A56.967,56.967,0,1,0,56.969,113.93a56.965,56.965,0,0,0,56.965-56.965" transform="translate(593 748.15)" fill="#fff"/>
                                <path d="M10.109,52.423V13.28a4.638,4.638,0,0,1,6.948-4.024L57.529,28.808a4.636,4.636,0,0,1,0,8.049L17.057,56.41a4.622,4.622,0,0,1-6.948-3.986" transform="translate(621.271 772.282)" fill="#1b3b87"/>
                            </g>
                        </svg>
                    </div>
                )}
                <video playsInline={true} muted={false} disablePictureInPicture={true} controlsList={"nodownload noplaybackrate"} ref={animationVideoRef} onClick={changePlayState} autoPlay={false} controls={false} loop={false} width="100%" src="https://cdn.prestavi.com/website/prestavi-introduction.mp4" />
            </div>


            {/*<div className={styles.Wave}></div>*/}
        </div>
    );
};

export default Hero;
