// extracted by mini-css-extract-plugin
export var Attachment = "Problems-module--Attachment--D+DB0";
export var Author = "Problems-module--Author--Wr4EW";
export var Content = "Problems-module--Content--aHo8F";
export var Email = "Problems-module--Email--9Uqoc";
export var ForgottenEmail = "Problems-module--ForgottenEmail--ea4hf";
export var Heading = "Problems-module--Heading--7fdWA";
export var Item = "Problems-module--Item--yDHNN";
export var Message = "Problems-module--Message--q1TMC";
export var Messages = "Problems-module--Messages--e-Cz3";
export var Problems = "Problems-module--Problems--I8iEc";
export var Stream = "Problems-module--Stream--EESir";
export var Top = "Problems-module--Top--BRA8N";