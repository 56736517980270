// extracted by mini-css-extract-plugin
export var Blob = "Solutions-module--Blob--0vT+T";
export var CallToAction = "Solutions-module--CallToAction--WV-WY";
export var Container = "Solutions-module--Container--KZN14";
export var Details = "Solutions-module--Details--mzPxx";
export var Example = "Solutions-module--Example--jw+0X";
export var Examples = "Solutions-module--Examples--pWiWZ";
export var Features = "Solutions-module--Features--xMH1o";
export var Heading = "Solutions-module--Heading--jON3g";
export var Headline = "Solutions-module--Headline--r5DIT";
export var Intro = "Solutions-module--Intro--1KVB2";
export var JobOverview = "Solutions-module--JobOverview--ohzlX";
export var Jobs = "Solutions-module--Jobs--8YoS-";
export var JobsLink = "Solutions-module--JobsLink--ynbdS";
export var Logs = "Solutions-module--Logs--pcc2B";
export var Media = "Solutions-module--Media--feyUZ";
export var Solutions = "Solutions-module--Solutions--TmEKA";
export var Square = "Solutions-module--Square--T3UGy";
export var Story = "Solutions-module--Story--W8W4W";
export var Timeline = "Solutions-module--Timeline--q79jw";
export var Triangle = "Solutions-module--Triangle--SYg2z";
export var Workflows = "Solutions-module--Workflows--NiNFC";