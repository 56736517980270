import React from "react";

const styles = require("./Spotlight.module.scss");

interface Props {
    hideHeading?: boolean;
    onClickCaseStudy?: () => void;
}

const Spotlight: React.FunctionComponent<Props> = ({hideHeading, onClickCaseStudy}) => {
	return (
		<div className={styles.Spotlight}>
            {!hideHeading && (
                <div className={styles.Title}>
                    <h2>Customer Spotlight</h2>
                    <p>Real stories from businesses that thrive with Prestavi.</p>
                </div>
            )}

            <div className={styles.Content}>
                <div className={styles.Testimonial}>
                    <div className={styles.BackgroundCircle}></div>
                    <div className={styles.Quote}>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 14.7 11.951">
                                <path d="M6.239,1.587A7.741,7.741,0,0,0,2.855,6.5c.053,0,.423-.053.688-.053a2.516,2.516,0,0,1,2.538,2.7,2.787,2.787,0,0,1-2.8,2.8C1.533,11.951,0,10.523,0,7.932A10.057,10.057,0,0,1,4.283,0Zm8.461,0A7.942,7.942,0,0,0,11.316,6.5c.053,0,.423-.053.687-.053a2.557,2.557,0,0,1,2.591,2.7,2.833,2.833,0,0,1-2.855,2.8c-1.745,0-3.279-1.428-3.279-4.019A10.058,10.058,0,0,1,12.743,0Z" fill="#5583ed"/>
                            </svg>

                            <p>
                                Prestavi has dramatically changed the way we operate and helped us solve the challenges that came with scaling our business.
                            </p>
                        </div>

                        <div className={styles.Author}>
                            <img src="/assets/home/stefan.png" />
                            <div>
                                <p>Stefan Howlett</p>
                                <span>Founder & CEO of <a target="_blank" href="https://www.rolloverrep.com/">Rollover Rep</a></span>
                            </div>
                        </div>
                    </div>

                    <div className={styles.Office}>
                        <img src="https://cdn.prestavi.com/website/home/rollover-rep-office.png" alt="Rollover Rep's Office"/>
                    </div>
                </div>

                <div className={styles.Story}>
                    <div>
                        <img src="/assets/home/rollover-rep-logo.png" />

                        <h3>Learn how Rollover Rep has rapidly grown their business by <span>30x</span> with Prestavi.</h3>
                        {/*<h3>Learn how Rollover Rep has scaled their operation and processed over <span>150,000</span> calls with Prestavi.</h3>*/}

                        <a href="https://cdn.prestavi.com/rollover-rep-case-study.pdf" onClick={onClickCaseStudy} target="_blank">
                            Read the story
                            <svg xmlns="http://www.w3.org/2000/svg" width="22.056" height="18.393" viewBox="0 0 22.056 18.393">

                                <g transform="translate(-155 -2.304)">
                                    <g transform="translate(155 2.304)">
                                        <g>
                                            <path d="M1.27,18.393a1.27,1.27,0,0,1-.891-2.174L7.9,8.811.43,2.222A1.27,1.27,0,1,1,2.11.318L10.6,7.808a1.269,1.269,0,0,1,.051,1.856L2.161,18.028a1.267,1.267,0,0,1-.891.365" transform="translate(0 0)" fill="#bfd0f7"/>
                                        </g>
                                    </g>
                                    <g transform="translate(166.028 2.304)">
                                        <g>
                                            <path d="M1.27,18.393a1.27,1.27,0,0,1-.891-2.174L7.9,8.811.43,2.222A1.27,1.27,0,1,1,2.11.318L10.6,7.808a1.269,1.269,0,0,1,.051,1.856L2.161,18.028a1.267,1.267,0,0,1-.891.365" transform="translate(0 0)" fill="#265dde"/>
                                        </g>
                                    </g>
                                </g>
                            </svg>

                        </a>
                    </div>

                </div>
            </div>

		</div>
	);
};

export default Spotlight;
