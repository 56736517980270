import React from "react";

const styles = require("./Support.module.scss");

interface Props {}

const Support: React.FunctionComponent<Props> = () => {
	return (
		<div className={styles.Support}>

            <div className={styles.Heading}>
                <h1>We've got your back.</h1>
                <h2>Providing great support is our superpower.</h2>
                <p>Our founder started his career as a technical support representative for a software company and knows first hand how much of a difference in overall experience that amazing support can make.</p>
            </div>
            <div className={styles.Content}>
                <div className={styles.Reviews}>
                     <div className={styles.Review}>
                         <img src="https://cdn.prestavi.com/website/images/capterra-logo.png" alt="Capterra logo"/>

                         <div className={styles.Quotes}>
                             <svg xmlns="http://www.w3.org/2000/svg" width="17.695" height="14.386" viewBox="0 0 17.695 14.386">
                                 <path d="M7.51,1.91C5.474,3.247,3.628,5.793,3.437,7.829c.064,0,.509-.063.828-.063A3.029,3.029,0,0,1,7.32,11.012a3.355,3.355,0,0,1-3.373,3.374C1.846,14.386,0,12.667,0,9.548A12.106,12.106,0,0,1,5.156,0Zm10.185,0a9.561,9.561,0,0,0-4.074,5.919c.063,0,.509-.063.827-.063a3.078,3.078,0,0,1,3.119,3.246,3.41,3.41,0,0,1-3.437,3.374c-2.1,0-3.947-1.719-3.947-4.838A12.107,12.107,0,0,1,15.34,0Z" fill="#5583ed"/>
                             </svg>
                         </div>

                         <div>
                             <p>CUSTOMER SUPPORT: A+++</p>
                             <span>Torben from a Capterra review</span>
                         </div>
                     </div>
                    <div className={styles.Review}>
                        <img src="https://cdn.prestavi.com/website/images/capterra-logo.png" alt="Capterra logo"/>
                        <div className={styles.Quotes}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17.695" height="14.386" viewBox="0 0 17.695 14.386">
                                <path d="M7.51,1.91C5.474,3.247,3.628,5.793,3.437,7.829c.064,0,.509-.063.828-.063A3.029,3.029,0,0,1,7.32,11.012a3.355,3.355,0,0,1-3.373,3.374C1.846,14.386,0,12.667,0,9.548A12.106,12.106,0,0,1,5.156,0Zm10.185,0a9.561,9.561,0,0,0-4.074,5.919c.063,0,.509-.063.827-.063a3.078,3.078,0,0,1,3.119,3.246,3.41,3.41,0,0,1-3.437,3.374c-2.1,0-3.947-1.719-3.947-4.838A12.107,12.107,0,0,1,15.34,0Z" fill="#5583ed"/>
                            </svg>
                        </div>

                        <div>
                            <p>I'm very impressed with the responsiveness of their team to our questions. </p>
                            <span>Cameron from a Capterra review</span>
                        </div>
                    </div>

                    <a className={styles.ScheduleCall} href="/schedule-a-call">Schedule a call with a Prestavi expert.</a>
                </div>

                <div className={styles.ThumbsUp}>
                    <img src="https://cdn.prestavi.com/website/images/thumbs-up.png" alt="Thumbs up"/>
                </div>
            </div>
		</div>
	);
};

export default Support;
