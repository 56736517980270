// extracted by mini-css-extract-plugin
export var Author = "Spotlight-module--Author--JgqmU";
export var Background = "Spotlight-module--Background--AJubK";
export var BackgroundCircle = "Spotlight-module--BackgroundCircle--OqA5R";
export var Content = "Spotlight-module--Content--gTE-u";
export var Office = "Spotlight-module--Office--9wolc";
export var Quote = "Spotlight-module--Quote--cjUCa";
export var Spotlight = "Spotlight-module--Spotlight--X7Uuo";
export var Story = "Spotlight-module--Story--sxwgV";
export var Testimonial = "Spotlight-module--Testimonial--Jauf5";
export var Title = "Spotlight-module--Title--15JKU";