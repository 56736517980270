import React, {MutableRefObject, useEffect, useRef} from "react";
import Hero from "./Hero/Hero";
import Spotlight from "./Spotlight/Spotlight";
import Callout from "./Callout/Callout";
import Footer from "../../Footer/Footer";
import Automations from "./Automations/Automations";
import Jobs from "./Jobs/Jobs";
import Support from "./Support/Support";
import Problems from "./Problems/Problems";
import Solutions from "./Solutions/Solutions";

const styles = require("./Home.module.scss");

interface Props {
}

const Home: React.FunctionComponent<Props> = () => {
    const animationVideoRef: MutableRefObject<HTMLVideoElement|null> = useRef(null);

    return (
        <div className={styles.Home}>

            <Hero animationVideoRef={animationVideoRef} />

            <Problems />
            <Solutions />

            <Spotlight />

            {/*<Automations />*/}
            {/*<Jobs/>*/}

            <Support />

            <Callout/>
            <Footer />
        </div>
    );
};

export default Home;
