import React from "react";
import {Link} from "gatsby";

const styles = require("./Solutions.module.scss");

interface Props {}

const Solutions: React.FunctionComponent<Props> = () => {
	return (
		<div className={styles.Solutions}>
		    <div className={styles.Heading}>
                <h1>The better way, <span>the Prestavi way.</span></h1>
                <p>We want to help your team do more focused work, always know where to go and how to do their part of a process.</p>
            </div>

			<div className={styles.Workflows}>
				<div>
					<h2>Create blueprints for your business processes.</h2>
					<h5>Systemize your business</h5>

					<p>
						Workflows represent a business process in Prestavi and act as a blueprint that people use to create jobs. They can include all types of elements like steps and automations.
					</p>

					<div className={styles.Features}>
						<p>No-code drag and drop workflow builder</p>
						<p>Powerful user assignments</p>
						<p>Provide public access to specific steps</p>
						<p>Add a variety of content types to each step</p>
						<p>Flexible conditional routing</p>
					</div>

					<Link to="/platform/workflows">
						Learn more about workflows
						<svg xmlns="http://www.w3.org/2000/svg" width="9.36" height="15.611" viewBox="0 0 9.36 15.611">
							<g transform="translate(0)">
								<g transform="translate(0)">
									<path d="M1.078,15.611a1.078,1.078,0,0,1-.756-1.845L6.7,7.478.365,1.886A1.078,1.078,0,0,1,1.791.27l7.2,6.357A1.077,1.077,0,0,1,9.038,8.2l-7.2,7.1a1.076,1.076,0,0,1-.756.31" transform="translate(0 0)" fill="#2253c7"/>
								</g>
							</g>
						</svg>
					</Link>
				</div>

				<div>
					<img src="https://cdn.prestavi.com/website/home/workflow-builder-preview.png" alt="Workflow Builder User Interface Preview"/>
					<svg className={styles.Square} xmlns="http://www.w3.org/2000/svg" width="355" height="334" viewBox="0 0 355 334">
						<rect id="Rectangle_3973" data-name="Rectangle 3973" width="355" height="334" rx="15" fill="#bfd0f7"/>
					</svg>

					{/*<Link to="/templates" className={styles.CallToAction}>*/}
					{/*	<h3>Ready to Use Templates</h3>*/}
					{/*	<svg xmlns="http://www.w3.org/2000/svg" width="25.936" height="15.922" viewBox="0 0 25.936 15.922">*/}
					{/*		<g transform="translate(-1149.001 -2368.907)">*/}
					{/*			<path d="M22.747,2.8H1.194A1.311,1.311,0,0,1,0,1.4,1.31,1.31,0,0,1,1.194,0H22.747A1.31,1.31,0,0,1,23.94,1.4a1.311,1.311,0,0,1-1.193,1.4" transform="translate(1172.941 2378.133) rotate(180)" fill="#fff"/>*/}
					{/*			<path d="M12.291,13.994a.961.961,0,0,1,.17,1.537,1.5,1.5,0,0,1-1.03.391,1.527,1.527,0,0,1-.86-.249L.541,8.823a.851.851,0,0,1-.23-.178.359.359,0,0,1-.08-.089.76.76,0,0,1-.1-.142.065.065,0,0,1-.02-.036.407.407,0,0,1-.06-.151.847.847,0,0,1,.09-.755.522.522,0,0,1,.09-.133c.02-.027.05-.053.07-.08a.939.939,0,0,1,.18-.151l.06-.036L10.571.257a1.557,1.557,0,0,1,1.89.133.955.955,0,0,1-.17,1.537L3.431,7.952Z" transform="translate(1174.937 2384.829) rotate(180)" fill="#fff"/>*/}
					{/*		</g>*/}
					{/*	</svg>*/}
					{/*</Link>*/}

					<Link to="/getting-started" className={styles.CallToAction}>
						<h3>Watch Getting Started Videos</h3>
						<svg xmlns="http://www.w3.org/2000/svg" width="25.936" height="15.922" viewBox="0 0 25.936 15.922">
							<g transform="translate(-1149.001 -2368.907)">
								<path d="M22.747,2.8H1.194A1.311,1.311,0,0,1,0,1.4,1.31,1.31,0,0,1,1.194,0H22.747A1.31,1.31,0,0,1,23.94,1.4a1.311,1.311,0,0,1-1.193,1.4" transform="translate(1172.941 2378.133) rotate(180)" fill="#ffffff"/>
								<path d="M12.291,13.994a.961.961,0,0,1,.17,1.537,1.5,1.5,0,0,1-1.03.391,1.527,1.527,0,0,1-.86-.249L.541,8.823a.851.851,0,0,1-.23-.178.359.359,0,0,1-.08-.089.76.76,0,0,1-.1-.142.065.065,0,0,1-.02-.036.407.407,0,0,1-.06-.151.847.847,0,0,1,.09-.755.522.522,0,0,1,.09-.133c.02-.027.05-.053.07-.08a.939.939,0,0,1,.18-.151l.06-.036L10.571.257a1.557,1.557,0,0,1,1.89.133.955.955,0,0,1-.17,1.537L3.431,7.952Z" transform="translate(1174.937 2384.829) rotate(180)" fill="#ffffff"/>
							</g>
						</svg>
					</Link>
				</div>
			</div>

			<div className={styles.Jobs}>
				<h2>People create jobs from blueprints</h2>
				<h5>WORK ASYNCHRONOUSLY</h5>
				<p>
					Give people the freedom to do their part of a process and complete it correctly (the first time) without having to worry about interrupting anyone.
				</p>

				<div className={styles.Examples}>
					<div className={styles.Example}>
						Employee Onboarding
					</div>
					<div className={styles.Example}>
						Employee Offboarding
					</div>
					<div className={styles.Example}>
						Employee Role Change
					</div>
					<div className={styles.Example}>
						System Access Request
					</div>
					<div className={styles.Example}>
						Submit Feedback to HR
					</div>
					<div className={styles.Example}>
						New Service Request
					</div>
				</div>
				<div className={styles.Story}>
					<div className={styles.Intro}>
						<div>
							<svg xmlns="http://www.w3.org/2000/svg" width="85" height="84" viewBox="0 0 85 84">
								<rect width="85" height="84" fill="#fff"/>
							</svg>
							<img src="https://cdn.prestavi.com/website/home/person-headshot.jpeg" alt="Person Headshot"/>
						</div>
						<div>
							<span>Example Situation | Based on a Real Story</span>
							<p>Aubrey needed access to a new system.</p>
						</div>
					</div>
					<div className={styles.Details}>
						<h6>Without Prestavi</h6>
						<p>Aubrey didn't know who to ask for permission.</p>
						<p>Four people unnecessarily got involved, were pulled out of their work and wasted time when only one specific person could grant her access.</p>
					</div>
					<div className={styles.Details} style={{marginTop:'32px'}}>
						<h6>With Prestavi</h6>
						<p>Aubrey knew where to go and started a job for System Access Request workflow.</p>
						<p>She filled out a simple form with the exact information required to provide her with the access she needed.</p>
						<p>Her request went immediately to the correct person.</p>
					</div>

					<img src="https://cdn.prestavi.com/website/home/example-workflow-step.png" />
					<Link to="/platform/jobs" className={styles.JobsLink}>
						Learn more about jobs

						<svg xmlns="http://www.w3.org/2000/svg" width="9.36" height="15.611" viewBox="0 0 9.36 15.611">
							<g transform="translate(0)">
								<g transform="translate(0)">
									<path d="M1.078,15.611a1.078,1.078,0,0,1-.756-1.845L6.7,7.478.365,1.886A1.078,1.078,0,0,1,1.791.27l7.2,6.357A1.077,1.077,0,0,1,9.038,8.2l-7.2,7.1a1.076,1.076,0,0,1-.756.31" transform="translate(0 0)" fill="#24624D"/>
								</g>
							</g>
						</svg></Link>
				</div>
			</div>

			<div className={styles.JobOverview}>
				<div className={styles.Container}>
					<div className={styles.Headline}>
						<h2>Track and measure work that is often invisible.</h2>
						<h3>From a quick overview to all of the nitty gritty details.</h3>
						<p>It used to be nearly impossible to track and measure how often your team is interrupted. With Prestavi, people submit requests and questions through streamlined processes that make it easy to keep track of every detail.</p>
					</div>
				</div>

				<div className={styles.Media}>
					<img className={styles.Timeline} src="https://cdn.prestavi.com/website/images/job-timeline-preview.png" alt=""/>
					<img className={styles.Logs} src="https://cdn.prestavi.com/website/images/job-logs-example.png" alt=""/>

					<svg className={styles.Blob} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 221.635 221.635">
						<path d="M197.759,36.608c-3.488-28.562-45.083-39.1-83.941-36.12C60.6,4.57-54.282.147,29.877,84.138c80.559,80.4,171.847-15.067,167.882-47.53" transform="translate(139.925 221.635) rotate(-135)" fill="#5583ed"/>
					</svg>

					<svg className={styles.Triangle} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 145.051 145.05">
						<g transform="translate(392.974 3095.025) rotate(180)">
							<path d="M132.673,0V132.674h-32.03V32.03H0V0Z" transform="translate(380.597 3095.025) rotate(180)" fill="#54b997"/>
							<path d="M161.27,31.347H31.347v29.28H131.991V161.27h29.28Zm2.751-2.751V164.02h-34.78V63.376H28.6V28.6Z" transform="translate(421.571 3113.995) rotate(180)" fill="#b9e5d6"/>
						</g>
					</svg>
				</div>
			</div>
		</div>
	);
};

export default Solutions;
